import React from 'react';
import { formatCurrency } from '../../../utils/formatters';
import { formatNumber } from '../../../utils/formatters';

const Table = ({
  participants,
  amount,
  paymentFrequency,
  durationDates,
  eligibility,
  medianHouseholdIncome,
}) => {
  const [tableRows, setTableRows] = React.useState([
    {
      title: 'Number of Participants',
      value: `${formatNumber(participants)} individuals`,
    },
    /*{
      title: 'Median Household Income',
      value:
        medianHouseholdIncome || medianHouseholdIncome === 0
          ? `${formatCurrency(medianHouseholdIncome)} per year`
          : 'N/A',
    },*/
    {
      title: 'Amount',
      value: typeof amount === 'string' ? amount : formatCurrency(amount),
    },
    {
      title: 'Frequency',
      value: paymentFrequency,
    },
    {
      title: 'Program Dates',
      value: durationDates,
    },
    {
      title: 'Eligibility',
      value: eligibility,
    },
  ]);

  return (
    <div className="container mx-auto pl-3 lg:pl-5 pr-3 md:pr-5 md:mb-5 flex-grow">
      <div className="table">
        <table className="table-fixed w-full mt-4">
          <thead className="text-left text-white" style={{ backgroundColor: '#00505C' }}>
            <tr className="mx-5">
              <th className="py-3 uppercase">Program Features</th>{' '}
              {/* TODO: Replace 'Pilot' with acronym */}
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {tableRows.map((item) => {
              return (
                <tr key={item.title}>
                  <td className="">{item.title}</td>
                  <td className="">{item.value}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
