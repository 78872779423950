export const formatCurrency = (num, options = {}) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...options,
  });

  return formatter.format(num);
};

export const formatNumber = (num, options = {}) => {
  const formatter = new Intl.NumberFormat('en-US', {
    ...options,
  });

  return formatter.format(num);
};
