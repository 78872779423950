import React, { useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { formatCurrency } from '../../../utils/formatters';
import { formatNumber } from '../../../utils/formatters';
import { motion, useScroll, useTransform } from 'framer-motion';

// Multiplied by 100px to handle parallax offset of background
const PARALLAX_SPEED = 1;

const Hero = ({
  pilotCity,
  pilotName,
  pilotSummary,
  participants,
  amount,
  duration,
  invested,
  slug,
}) => {
  const isTablet = useMediaQuery({
    minWidth: 640,
    maxWidth: 1024,
  });
  const values = [
    {
      title: typeof participants === 'number' ? formatNumber(participants) : participants,
      text: 'participants',
    },
    {
      title: typeof amount === 'number' ? formatCurrency(amount) : amount,
      text: 'monthly payment',
    },
    {
      title: duration,
      text: 'duration ',
    },
    {
      title: formatCurrency(invested),
      text: 'invested in community',
    },
  ];

  const ref = useRef(null);

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start 75px', 'end start'],
  });

  const backgroundY = useTransform(scrollYProgress, [0, 1], [`0px`, `${100 * PARALLAX_SPEED}px`]);

  let paymentTextFontSize = '';

  if (pilotCity === 'Gainesville') {
    paymentTextFontSize = '2rem';
  } else if (pilotCity === 'New York City') {
    paymentTextFontSize = '3rem';
  }

  return (
    <>
      <motion.div className="w-full h-full" style={{ translateY: backgroundY }}>
        <div
          ref={ref}
          className={`flex-grow flex flex-col h-[640px] md:h-[600px] lg:h-[640px] pilots-bg bg-forest ${slug
            ?.replaceAll(' ', '-')
            .toLowerCase()}-hero`}
          style={{
            backgroundImage: `url(/images/${slug}-hero.jpg)`,
          }}
        ></div>
      </motion.div>
      <div
        className={`hero-overlay top-0 ${pilotName
          ?.replaceAll(' ', '-')
          .toLowerCase()} h-[640px] md:h-[600px] lg:h-[640px]`}
      ></div>
      <div className="container absolute top-0 mx-auto px-3 md:px-5 h-full flex flex-col justify-end py-5 lg:py-8 z-10">
        <div className="flex flex-col sm:flex-row items-start gap-4 lg:gap-13 pb-0 sm:pb-2">
          <div className="w-full sm:w-1/2">
            <h1 className="text-white mb-3">
              {pilotName}
              {/* , SC{' '} TODO get state data, or parse from location */}
            </h1>
            {isTablet ? null : <p className="text-white">{pilotSummary}</p>}
          </div>
          <div className="w-full sm:w-1/2 flex justify-between flex-wrap">
            {values.map((item, index) => {
              return (
                <div
                  key={item.title}
                  className="w-1/2 md:text-white text-white lg:text-white xl:text-white 2xl:text-white self-end"
                >
                  <p
                    className="pilotnumbers mb-[6px]"
                    style={{
                      fontSize: index === 1 && typeof amount !== 'number' && paymentTextFontSize,
                    }}
                  >
                    {item.title}
                  </p>
                  <p>{item.text}</p>
                </div>
              );
            })}
          </div>
        </div>
        {isTablet ? <p className="text-white">{pilotSummary}</p> : null}
      </div>
    </>
  );
};
export default Hero;
