import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const Footer = ({
  photo,
  pullQuote,
  participantName,
  pilotName,
  pilotLocation,
  pilotCity,
  durationDates,
}) => {
  return (
    <div className="container mx-auto px-3 md:px-5 lg:px-7 my-8 lg:my-[88px]">
      <div className="flex flex-col md:flex-row md:gap-10">
        <div className="flex-1 flex flex-col items-center">
          <GatsbyImage
            className={`story-images story-image-${pilotCity.toLowerCase()}-${participantName.toLowerCase()} mr-3 w-full h-[240px] md:h-[480px] object-contain`}
            image={getImage(photo)}
            alt={participantName}
          />
        </div>
        <div className="flex-1 flex flex-col items-center justify-center mt-5 md:mt-0">
          <h4 className="text-white">{pullQuote}</h4>
          <p className="headings text-white mt-5">{participantName}</p>
          <p className="headings text-white">{pilotName}</p>
          <p className="headings text-white">{pilotLocation}</p>
          <div className="flex justify-center mt-5">
            <Link to="/stories">
              <button className="button bg-bronze cta2 text-white cursor-pointer">
                View Stories
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
