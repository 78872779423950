import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';

const News = ({ pilotID }) => {
  const data = useStaticQuery(graphql`
    query relatedNewsItems {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/in-the-news.md/" } }) {
        edges {
          node {
            frontmatter {
              newsStories {
                date
                link
                source
                title
                relatedPilots
              }
            }
          }
        }
      }
    }
  `);

  const items = data?.allMarkdownRemark?.edges?.[0]?.node?.frontmatter?.newsStories;

  const newsItems = items
    .filter((obj) => obj.relatedPilots?.includes(pilotID))
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .slice(0, 3);

  const NewsItem = (item) => {
    const data = item.data;

    const date = new Date(data.date).toLocaleDateString(undefined, {
      month: 'long',
      year: 'numeric',
    });

    return (
      <div className="news-item-b block">
        <a
          className="block text-black h-full flex flex-col lg:flex-row gap-x-4"
          href={data.link}
          target="_blank"
        >
          <span className="flex flex-none basis-1/3 lg:basis-2/5 text-[12px] lg:text-[14px] leading-[18px] lg:leading-[20px] tracking-[1.8px] uppercase font-bold my-auto mb-3 lg:mb-auto">
            {date} - {data.source}
          </span>
          <h5 className="text-black text-[18px] lg:text-[21px] leading-[24px] lg:leading-[1.5] tracking-[.1px] font-bold">
            {data.title}
          </h5>
        </a>
      </div>
    );
  };

  if (newsItems.length) {
    return (
      <div id="in-the-news" className="container mx-auto px-3 lg:px-5 py-8 lg:py-[88px]">
        <div className="bg-tan px-4 md:px-8 lg:px-11 py-6 lg:py-8">
          <h2 className="mb-5">In The News</h2>
          <div className="flex flex-col gap-y-5">
            {newsItems.map(function (item, i) {
              return <NewsItem data={item} key={i} />;
            })}
          </div>
          <div className="flex mt-5 lg:mt-7 justify-center">
            <Link to="/in-the-news">
              <button className="text-white uppercase text-[13px] md:text-[14px] !font-[800] tracking-[1.8px] w-[218px] md:w-[260px] h-[48px] md:h-[66px] bg-[#B26F16]">
                View All News
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  } else {
    return;
  }
};

export default News;
