import React, { useState } from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

const Tooltip = ({
  children,
  content,
  arrow = false,
  // open,
  defaultOpen,
  side = 'top',
  delayDuration = 300,
  onOpenChange,
  alwaysOpen,
  ...props
}) => {
  const [open, setOpen] = useState();
  return (
    <TooltipPrimitive.Root
      open={alwaysOpen || open}
      defaultOpen={defaultOpen}
      delayDuration={delayDuration}
      onOpenChange={setOpen}
    >
      <TooltipPrimitive.Trigger onClick={() => setOpen(true)} asChild>
        {children}
      </TooltipPrimitive.Trigger>
      <TooltipPrimitive.Content
        className="rounded bg-[#484848] text-[12px] max-w-[200px] text-white drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] p-1"
        side={side}
        align="center"
        {...props}
      >
        {content}
        {arrow && <TooltipPrimitive.Arrow fill="#484848" width={11} height={5} />}
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Root>
  );
};

export default Tooltip;
