import React from 'react';
import Tooltip from '../../../components/Tooltip';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { AiOutlineInfoCircle } from 'react-icons/ai';

const Demo = ({
  pilotCity,
  pilotName,
  pilotLocation,
  demographicPDF,
  demographicsData,
  pilotShortName,
}) => {
  const {
    // pilot,
    pilot_id,
    sample,
    avg_age,
    male,
    female,
    other,
    child_hh_yes,
    child_hh_no,
    avg_child_hh,
    avg_hh_size,
    hispanic,
    non_hispanic,
    white,
    african_am,
    ai_an,
    asian,
    nh_pi,
    latinx,
    single,
    married,
    partnered,
    language_english,
    language_hispanic,
    language_other,
    hh_income_mean,
    hh_income_median,
    metro_hh_income_median,
  } = demographicsData;

  let cities;
  if (pilot_id === '9d3300b2-6555-446e-b5c6-efbf86808e8f') cities = pilotLocation.split(' and ');
  if (
    ['7e0eb4c7-8060-4869-92c6-e404f3672c96', '703d72eb-5290-4953-8b08-615463bdebff'].includes(
      pilot_id,
    )
  )
    pilotLocation = 'St. Paul, MN';

  const calculateWidth = (value, referenceValue) => {
    const numericValue = parseFloat(value.replace(/,/g, ''));
    const numericReference = parseFloat(referenceValue.replace(/,/g, ''));

    return (numericValue / numericReference) * 100;
  };

  const containerOne = hh_income_median;
  const containerTwo = metro_hh_income_median;

  const formatString = (str) => (str.length === 0 ? 'N/A' : str + '%');

  return (
    <div className="container mx-auto px-3 md:px-5 pt-6 md:pt-7 lg:pt-9 pb-8 md:pb-9 lg:pb-[88px]">
      <h2 className="mb-6">Demographic Data for Study Participants</h2>

      <div className="flex flex-col md:flex-row gap-4 justify-between text-[12px]">
        <div className="w-full md:w-[280px]">
          <Tooltip
            side="right"
            content="The middle income among all households, where half of households have income above this level and half below."
          >
            <span className="w-full border-b-[1px] border-black block uppercase font-extrabold text-[12px] tracking-80	pb-1 genericTooltip cursor-default">
              Median Annual Household income <AiOutlineQuestionCircle />{' '}
            </span>
          </Tooltip>
          <div className="my-2 grid grid-cols-2 items-center">
            <p className="font-bold">{pilotShortName ? pilotShortName : pilotName}</p>

            {
              // Special cases for New Orleans & Indianapolis where there is no income data
              pilot_id === '9d3300b2-6555-446e-b5c6-efbf86808e8f' ||
              pilot_id === '318ebd47-a913-4685-838d-16dcc261d04a' ? (
                <p className="self-center inline-block leading-[1.8] pl-1 ml-1">
                  {hh_income_median}
                </p>
              ) : (
                <p
                  className="self-center inline-block leading-[1.8] pl-1 ml-1"
                  style={{
                    backgroundColor: '#EFA241',
                    width: `${calculateWidth(containerOne, containerTwo)}%`,
                  }}
                >
                  ${hh_income_median}
                </p>
              )
            }

            <Tooltip side="right" content="The larger community in which the pilot is located.">
              <p className="font-bold medianhh genericTooltip">
                <span className="mr-[2px]">
                  {pilot_id === '9d3300b2-6555-446e-b5c6-efbf86808e8f' ? cities[0] : pilotLocation}
                </span>
                <AiOutlineInfoCircle />
              </p>
            </Tooltip>
            <p
              className="inline-block leading-[1.8] pl-1 ml-1"
              style={{
                backgroundColor: '#BFDCE0',
                width: `${calculateWidth(containerTwo, containerTwo)}%`,
              }}
            >
              ${metro_hh_income_median}
            </p>
            {pilot_id === '9d3300b2-6555-446e-b5c6-efbf86808e8f' && (
              <>
                <Tooltip side="right" content="The larger community in which the pilot is located.">
                  <p className="font-bold medianhh genericTooltip">
                    <span className="mr-[2px]">{cities[1]}</span>
                    <AiOutlineInfoCircle />
                  </p>
                </Tooltip>
                <p
                  className="inline-block leading-[1.8] pl-1 ml-1"
                  style={{
                    backgroundColor: '#BFDCE0',
                    width: `${calculateWidth(containerTwo, containerTwo)}%`,
                  }}
                >
                  ${metro_hh_income_median}
                </p>
              </>
            )}
          </div>
          <div className="border-t-[1px] border-black pt-4 grid grid-cols-2 items-center gap-1">
            <Tooltip
              side="right"
              content="The average household income, where the Income of all households is divided by the number of all households."
            >
              <p className="font-bold inline genericTooltip cursor-default">
                Annual Household Income (Mean) <AiOutlineQuestionCircle />
              </p>
            </Tooltip>
            {
              // Special case for NO & Indianapolis -- removing $ sign when no data
              pilot_id === '9d3300b2-6555-446e-b5c6-efbf86808e8f' ? (
                <p className="place-self-stretch text-right">{hh_income_mean}</p>
              ) : (
                <p className="place-self-stretch text-right">${hh_income_mean}</p>
              )
            }
            <p className="font-bold">Sample Size</p>
            <p className="place-self-stretch text-right">{sample}</p>
            <p className="font-bold">Avg. Age of Respondent</p>
            <p className="place-self-stretch text-right">{avg_age}</p>
            <p className="font-bold">Avg. Children in Household</p>
            <p className="place-self-stretch text-right">{avg_child_hh}</p>
            <p className="font-bold">Avg. Household Size</p>
            <p className="place-self-stretch text-right">{avg_hh_size}</p>
          </div>
        </div>
        <div className="md:w-[208px]">
          <span className="w-full border-b-[1px] border-black block uppercase font-extrabold text-[12px]	pb-1 tracking-80">
            gender
          </span>
          <div className="mt-2 grid grid-cols-2 items-center">
            <p className="font-bold">Male</p>
            <p className="place-self-stretch text-right">{male}%</p>
            <p className="font-bold">Female</p>
            <p className="place-self-stretch text-right">{female}%</p>
            <p className="font-bold">Other</p>
            <p className="place-self-stretch text-right">{other[0]}%</p>
          </div>
          <span className="w-full border-b-[1px] border-black block uppercase font-extrabold text-[12px] pb-1 tracking-80 mt-3">
            marital status
          </span>
          <div className="mt-2 grid grid-cols-2 items-center">
            <p className="font-bold">Married</p>
            <p className="place-self-stretch text-right">{formatString(married)}</p>
            <p className="font-bold">Single</p>
            <p className="place-self-stretch text-right">{formatString(single)}</p>
            <p className="font-bold">Partnered/In-Relationship</p>
            <p className="place-self-stretch text-right">{formatString(partnered)}</p>
          </div>
          <span className="w-full border-b-[1px] border-black block uppercase font-extrabold text-[12px]	pb-1 tracking-80 mt-3">
            children in household
          </span>
          <div className="mt-2 grid grid-cols-2 items-center">
            <p className="font-bold">Yes</p>
            <p className="place-self-stretch text-right">{formatString(child_hh_yes)}</p>
            <p className="font-bold">No</p>
            <p className="place-self-stretch text-right">{formatString(child_hh_no)}</p>
          </div>
        </div>
        <div className="md:w-[208px]">
          <span className="w-full border-b-[1px] border-black block uppercase font-extrabold text-[12px] pb-1 tracking-80">
            race
          </span>
          <div className="mt-2 grid grid-cols-2  items-center">
            <p className="font-bold">White</p>
            <p className="place-self-stretch text-right">{formatString(white)}</p>
            <p className="font-bold">African American</p>
            <p className="place-self-stretch text-right">{formatString(african_am)}</p>
            <p className="font-bold">American Indian/Alaska Native</p>
            <p className="place-self-stretch text-right">{formatString(ai_an)}</p>
            <p className="font-bold">Asian</p>
            <p className="place-self-stretch text-right">{formatString(asian)}</p>
            <p className="font-bold">Native Hawaiian/Pacific Islander</p>
            <p className="place-self-stretch text-right">{formatString(nh_pi)}</p>
            <p className="font-bold">Latino</p>
            <p className="place-self-stretch text-right">{formatString(latinx)}</p>
            <p className="font-bold">Other/Mixed</p>
            <p className="place-self-stretch text-right">{formatString(other[1])}</p>
          </div>
        </div>
        <div>
          <span className="w-full border-b-[1px] border-black block uppercase font-extrabold text-[12px]	pb-1 tracking-80">
            ethnicity
          </span>
          <div className="mt-2 grid grid-cols-2  items-center">
            <p className="font-bold">Hispanic</p>
            <p className="place-self-end"> {formatString(hispanic)}</p>
            <p className="font-bold">Non-Hispanic</p>
            <p className="place-self-end">{formatString(non_hispanic)}</p>
          </div>
          <span className="w-full border-b-[1px] border-black block uppercase font-extrabold text-[12px] pb-1 tracking-80 mt-3">
            primary language at home
          </span>
          <div className="mt-2 grid grid-cols-2 items-center">
            <p className="font-bold">English</p>
            <p className="place-self-end">{formatString(language_english)}</p>
            <p className="font-bold">Spanish</p>
            <p className="place-self-end">{formatString(language_hispanic)}</p>
            <p className="font-bold">Other</p>
            <p className="place-self-end">{formatString(language_other)}</p>
          </div>
        </div>
      </div>
      {/* <div className="w-[300px] mt-4">
        <a
          href={demographicPDF}
          target="_blank"
          rel="noreferrer noopener"
          className={`${pilotCity
            .replaceAll(' ', '-')
            .toLowerCase()} button border bg-bronze cta2 text-white cursor-pointer flex items-center justify-center`}
        >
          Get demographic data (PDF)
        </a> 
      </div> */}
    </div>
  );
};

export default Demo;
