import React from 'react';

const SpendingBreakdown = ({ spending = [] }) => {
  return (
    <>
      <p className="headings uppercase mb-2">Spending breakdown</p>
      <p className="">
        {spending[0]?.label} accounted for the largest share of spending, at {spending[0]?.value}%,
        followed by {spending[1]?.label} ({spending[1]?.value}%) and {spending[2]?.label} (
        {spending[2]?.value}%).
      </p>
    </>
  );
};

export default SpendingBreakdown;
