import React from 'react';

/* TODO: Replace hardcoded data with dynamic props */

const Features = () => {
  const values = [
    {
      title: 'Median household income',
      value: '$44,797',
      value2: '$38,000',
    },
    {
      title: 'Poverty rate',
      value: '21.2%',
      value2: '20%',
    },
    {
      title: 'Housing burden',
      value: '37.3%',
      value2: '31%',
    },
    {
      title: 'Childcare cost burden',
      value: '31.2%',
      value2: '40%',
    },
    {
      title: 'Unemployment rate',
      value: '8.5%',
      value2: '8%',
    },
  ];

  return (
    <>
      <div className="hidden">
        <div className="bg-[#00505C] text-white p-4">COMMUNITY FEATURES</div>
        <div className="bg-white p-5">
          <div className="flex my-5 flex-wrap justify-center">
            {values.map((item, index) => {
              return (
                <div key={index} className="w-32 sm:w-32 md:flex-1 lg:flex-1 xl:flex-1 2xl:flex-1">
                  <p>
                    <span className="bg-[#EFA241] py-2 px-4">{item.value}</span>
                  </p>
                  <p className="my-3">
                    <span className="bg-[#63A0A7] py-2 px-4">{item.value2}</span>
                  </p>
                  <div className="font-medium">{item.title}</div>
                </div>
              );
            })}
          </div>
          <div className="flex justify-center my-5">
            <div className="flex items-center">
              <div className="h-5 w-5 bg-[#EFA241] rounded-full"></div>
              <p className="mx-3">STOCKTON</p>
            </div>
            <div className="flex items-center">
              <div className="h-5 w-5 bg-[#63A0A7] rounded-full"></div>
              <p className="mx-3">STATE MEDIAN</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
